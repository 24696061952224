import './App.css';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import React from 'react';

function App() {
  return (
    <Router>
      <div className="App">
        <nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
          </ul>
        </nav>
        <Switch>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

const Home = () => {
  return(
        <header className="App-header">
          <p>
            This website is under development.
          </p>
        </header>
  );
}

const About = () => {
  return(
        <header className="App-header">
          <p>
            This about page is under development.
          </p>
        </header>
  );
}

export default App;
